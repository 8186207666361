<template>
	<div>
		<div class="d-flex align-items-center">
			<div class="mr-2">
				<span>Total Loans</span>
				<h1>{{ quantity }}</h1>
			</div>
			<div class="ml-auto">
				<div style="height: 80px; width: 200px">
					<vue-apex-charts type="donut" height="80" :options="chartOptions" :series="serie"></vue-apex-charts>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "EstimatedSales",
	props: {
		quantity: Number,
		serie: Array,
	},
	data: () => ({
		title: "Total Loans",
		chartOptions: {
			chart: {
				fontFamily: '"Nunito Sans",sans-serif',
				foreColor: "#a1aab2",
			},
			labels: ["Personal Loans", "Vehicle Loans"],
			dataLabels: {
				enabled: false,
			},
			grid: {
				padding: {
					left: 0,
					right: 0,
				},
				borderColor: "rgba(0,0,0,0.1)",
			},
			plotOptions: {
				pie: {
					donut: {
						size: "75px",
						labels: {
							show: true,
							name: {
								show: true,
								offsetY: 10,
							},
							value: {
								show: false,
							},
							total: {
								show: true,
								fontSize: "8px",
								color: "#a1aab2",
								label: "",
							},
						},
					},
				},
			},
			stroke: {
				width: 0,
			},
			legend: {
				show: false,
			},
			colors: ["#0A505D", "#C8BBFF"],
			tooltip: {
				fillSeriesColor: false,
			},
		},
	}),
	components: {
		VueApexCharts,
	},
};
</script>
