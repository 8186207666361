<template>
	<div>
		<!-- Chart and Data Section -->
		<vue-apex-charts type="area" height="168" :options="chartOptions" :series="transformedSeries"></vue-apex-charts>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "OverviewCampaignChart",
	components: {
		VueApexCharts,
	},
	props: {
		series: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			title: "OverviewCampaignChart",
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				chart: {
					fontFamily: '"Nunito Sans", sans-serif',
					foreColor: "#a1aab2",
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				stroke: {
					curve: "smooth",
					width: 2,
				},
				colors: ["#4dd0e1"],
				xaxis: {
					categories: this.generateCategories(),
					labels: {
						show: true,
						rotate: -45,
					},
				},
				yaxis: {
					labels: {
						show: true,
					},
				},
				markers: {
					size: 3,
					strokeColor: "transparent",
				},
				tooltip: {
					x: {
						format: "MM/yyyy",
					},
					theme: "dark",
				},
				legend: {
					show: false,
				},
				grid: {
					show: false,
				},
			},
		};
	},
	computed: {
		transformedSeries() {
			return this.series.map((item) => ({
				name: item.name,
				data: item.data.map((dataPoint) => dataPoint.numberOfLoans),
			}));
		},
	},
	methods: {
		generateCategories() {
			const categories = [];
			this.series[0].data.forEach((dataPoint) => {
				categories.push(`${dataPoint.monthName} ${dataPoint.year}`);
			});
			return categories;
		},
	},
};
</script>
